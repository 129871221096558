// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyDv1TYjlEVxunVuOrjCu3UrlgY73baGGvk",
    authDomain: "chr-gr.firebaseapp.com",
    databaseURL: "https://chr-gr-default-rtdb.firebaseio.com",
    projectId: "chr-gr",
    storageBucket: "chr-gr.appspot.com",
    messagingSenderId: "1023195390264",
    appId: "1:1023195390264:web:c7e21fac17b679f945e1cd"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getDatabase(app);
