import { useEffect, useState, Fragment } from 'react';
import './TaskView.css'
import { useParams, Link } from 'react-router-dom';

function TaskView() {
    const { taskId } = useParams();
    const [taskData, setTaskData] = useState(null);
    const [message, setMessage] = useState('');
    const [buttons, setButtons] = useState([]);

    useEffect(() => {
        setTaskData(null);
        if (taskId)
            import('./tasks/' + taskId).then((module) => {
                let levels = Object.keys(module.levels);
                let level = localStorage.getItem(taskId);
                if (!module.levels[level]) level = levels[0];
                localStorage.setItem(taskId, level);
                let answer = localStorage.getItem(taskId + ':' + level); // get from local storage
                setTaskData(() => ({
                    taskId: taskId,
                    level: level,
                    levels: levels,

                    Description: module.Description,
                    App: module.App,
                    initTask: module.initTask,

                    answer: answer
                }));
            });
    }, [taskId])

    if (!taskId) return <p>Undefined Task</p>;
    if (!taskData || !taskData.levels) return <span aria-busy="true">Γίνεται φόρτωση...</span>;

    function changeLevel(level) {
        setTaskData(td => ({
            ...td,
            level: level,
            answer: localStorage.getItem(taskId + ':' + level)
        }));
        localStorage.setItem(taskId, level);
    }


    function setAnswer(a) {
        localStorage.setItem(taskId + ':' + taskData.level, a);
        setTaskData(t => ({...t,answer:a}));
    }

    function setSavedAnswer(msg, data) {
        setMessage(msg);
    }
    return <>
    {false && 
        <fieldset className='level-selector'>
        {taskData.levels.map((option, index) => (<Fragment key={index} >
            <label>
                <input type="radio" name="level" 
                checked={option==taskData.level} 
                onChange={() => changeLevel(option)} />
        {option}</label>
        </Fragment>))}
        </fieldset>}
        <nav role="tab" style={{marginTop: -20}}>
            <ul>
                <li><b>Ζητούμενα:</b></li>
            {taskData.levels.map((option, index) => (
                <li key={index}><Link to="#" onClick={() => changeLevel(option)} role="tab" aria-current={option==taskData.level ? true : null}>{option}</Link></li>
        ))}
                
            </ul>
        </nav>
        <div className={'bebras-grid ' + taskData.level}>
            <div className='bebras-description'>
                <taskData.Description level={taskData.level} />
            </div>
            <div className={'bebras-task'}>
                <dialog open={!!message}>
                    <article>
                        <h2>{message}</h2>
                        <footer>
                            <button onClick={() => setMessage('')}>OK</button>
                            {taskData && taskData.levels &&
                                taskData.levels.indexOf(taskData.level) != taskData.levels.length - 1 &&
                                taskData.levels.indexOf(taskData.level) != -1 &&
                                <button onClick={() => {
                                    changeLevel(taskData.levels[
                                        taskData.levels.indexOf(taskData.level) + 1
                                    ]); setMessage('');
                                }}>Επόμενο</button>
                            }
                        </footer>
                    </article>
                </dialog>
                <div>
                    <taskData.App 
                        level={taskData.level} 
                        answer={taskData.answer} 
                        setAnswer={setAnswer} 
                        setSavedAnswer={setSavedAnswer} 
                        setButtons={setButtons} key={taskData.level} />

                    <div className="grid" style={{ marginTop: 15 }}>
                        {buttons && buttons.map((b,i) => 
                        <button key={i}
                            onClick={b.click}>
                            {b.name}
                        </button>)}
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default TaskView;