// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import '@picocss/pico/css/pico.min.css'; // Import Pico CSS
import { auth } from './firebase';

import HomePage from './pages/HomePage';
import Contests from './pages/Contests';
import Contest from './pages/Contest';
import Task from './pages/Task';
import Login from './pages/Login';
import Header from './components/Header'; // Import Header
import Epitropes from './pages/Epitropes';
import Vraveia from './pages/Vraveia';
import Kanonismos from './pages/Kanonismos';
import logo from './img/logo.png';
import { highlight } from 'prismjs';


function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(setUser);
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Header user={user} title={'ΠΔΤΝ'} menuOptions={[
        {path: "/tasks", name: "Α' φάση 2025", className: 'highlight'},
        {path: "/kanonismos", name: 'Κανονισμός'},
        {path: "/vraveia", name: 'Βραβεία'},
        {path: "/epitropes", name: 'Επιτροπές'},
      ]} />
      <main className="container">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contests" element={<Contests />} />
          <Route path="/kanonismos" element={<Kanonismos />} />
          <Route path="/epitropes" element={<Epitropes />} />
          <Route path="/vraveia" element={<Vraveia />} />
          <Route path="/contests/:contestId" element={<Contest />} />
          <Route path="/tasks" element={<Contest />} />
          <Route path="/tasks/:taskId" element={<Task />} />
          <Route
            path="/contests/:contestId/tasks/:taskId"
            element={<Task />}
          />
          <Route
            path="/login"
            element={<Login redirectPath={window.location.pathname} />}
          />
        </Routes>
      </main>
    </Router>
  );
}

export default App;
