import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ref, get } from 'firebase/database';
import { db } from '../firebase';
import styled from 'styled-components';
import logos from './logos';
import contests from './contests-data.json';

const Grid = styled.div`
    display: grid;

    grid-template-columns: 100% ;

    column-gap: 1%;
    row-gap: 15px;

    @media (min-width: 512px) {
        grid-template-columns: 49% 49% ;
    }

    @media (min-width: 1024px) {
        grid-template-columns: 32% 32% 32%;
    }

    a {
        display: flex;
        flex-direction: column;
        vertical-align: middle;
        border: 2px solid #444;
        border-radius: 5px;
        text-decoration: none;
        justify-content: flex-end;
        aspect-ratio: 3/2;
        background-size: contain;
        background-position: center center;

        border-radius: 10px;
        color: #777;
        box-shadow: 0 5px 5px -5px black;
        transition: transform .2s;
        cursor: pointer;
    }

    a:nth-child(odd) {
        transform: rotate(1deg);
    }
    a:nth-child(even) {
        transform: rotate(-1deg);
    }
    a:hover {
      transform: rotate(0) translateY(-10px);
    }

    .image {
        background-position: top center;
        background-size: cover;
        height: 12em;
        margin: 0 2em;
    }
    .name {
        text-align: center;
        background: rgba(255,255,255,0.5);
        padding: .5rem;
        margin: 0;
    }
`;

const defaultContest = "pdtn25a";

function Contest() {
    const [tasks, setTasks] = useState(false);
    let { contestId } = useParams();
    if(!contests[contestId]) contestId = defaultContest;
    let names = contests[contestId].tasks;

    let prefix = contestId === defaultContest ? "" : `/contests/${contestId}`;

    useEffect(() => {
        /*
        get(ref(db, `contests/${contestId}/tasks`)).then((snapshot) => {
            const taskIds = snapshot.val();
            setTasks( taskIds ? taskIds.map(x => ({id:x, name:names[x]}) ) : []);
        });
        */
        const taskIds = Object.keys(names);
        setTasks( taskIds ? taskIds.map(x => ({id:x, name:names[x]}) ) : []);
    }, [contestId]);
    if(!tasks) return <span aria-busy="true">Γίνεται φόρτωση...</span>;
    return (
        <div>
            <h1>{contests[contestId].name} - Προβλήματα</h1>
            <Grid style={{}}>
                {tasks.map((task) => (
                    <Link to={`${prefix}/tasks/${task.id}`} key={task.id}  style={{backgroundImage: `url(${logos[task.id]})` }}>
                            <h4 className='name'>{task.name}</h4>
                            
                    </Link>
                ))}
            </Grid>
        </div>
    );
}

export default Contest;
