import React from 'react';
import ReactMarkdown from 'react-markdown';

let md = `# Βραβεία / Έπαινοι

Η συμμετοχή στον Πανελλήνιο (Μαθητικό) Διαγωνισμό Τεχνητής Νοημοσύνης αναγνωρίζεται με βεβαίωση συμμετοχής, η οποία εκδίδεται με ευθύνη της Ερευνητικής Μονάδας ΑΡΧΙΜΗΔΗΣ του Ερευνητικού Κέντρου ΑΘΗΝΑ, της ΕΕΤΝ και της ΕΠΥ.

Η αυξημένη επίδοση στον ΠΔΤΝ επιβραβεύεται με έπαινο και ειδικά βραβεία. Πιο συγκεκριμένα, για τους συμμετέχοντες στον διαγωνισμό προβλέπονται οι παρακάτω έπαινοι και βραβεία:

- **Βεβαίωση συμμετοχής**  
  (για όλους τους μαθητές που λαμβάνουν μέρος στην προκαταρκτική φάση του διαγωνισμού)
- **Έπαινος**  
  (για όλους τους μαθητές που διακρίθηκαν στην προκαταρκτική φάση και λαμβάνουν μέρος στην τελική φάση του διαγωνισμού)
- **Βραβείο διάκρισης**  
  (για τους μαθητές που κατατάχθηκαν στο υψηλότερο 25% των συμμετεχόντων στην τελική φάση του διαγωνισμού)
- **Συμμετοχή στην Εθνική Ομάδα**  
  (για τους τέσσερις (4) μαθητές με την υψηλότερη βαθμολογία στην τελική φάση του διαγωνισμού, σύμφωνα και με τα κριτήρια επιλεξιμότητας και συγκρότησης των Εθνικών Ομάδων που λαμβάνουν μέρος στη Διεθνή Ολυμπιάδα Τεχνητής Νοημοσύνης και στους αντίστοιχους διεθνείς διαγωνισμούς)

Τα ονόματα των συμμετεχόντων που λαμβάνουν βραβείο διάκρισης ή/και επιλέγονται να στελεχώσουν την Εθνική Ομάδα μπορούν να καταχωρηθούν στον ιστότοπο του διαγωνισμού, στους πίνακες των διακριθέντων, εφόσον οι ίδιοι το επιθυμούν και παρέχεται η έγγραφη συγκατάθεση των γονέων ή των κηδεμόνων τους.

Οι βεβαιώσεις συμμετοχής, οι έπαινοι και τα βραβεία διάκρισης είναι έντυπα, χορηγούνται εγγράφως ή ηλεκτρονικά, εκδίδονται με ευθύνη της Ερευνητικής Μονάδας ΑΡΧΙΜΗΔΗΣ του Ερευνητικού Κέντρου ΑΘΗΝΑ, της ΕΕΤΝ και της ΕΠΥ, και δεν συνοδεύονται από χρηματικό έπαθλο ή άλλη υλική επιβράβευση.
`;


const MarkdownRenderer = ({ markdown }) => {
    return (
        <ReactMarkdown>{md}</ReactMarkdown>
    );
  };
  
  export default MarkdownRenderer;