// src/pages/Task.js
import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import TaskView from '../components/TaskView'
import contests from './contests-data.json'

const defaultContest = "pdtn25a";

function Task() {
  let { taskId, contestId } = useParams();
  if(!contestId) contestId = defaultContest;
  let names = contests[contestId].tasks;

  const tasks = Object.keys(names);
  const id = tasks.indexOf(taskId);
  if(id < 0) return "Το πρόβλημα δεν βρέθηκε.";
  const prev = tasks[id-1];
  const next = tasks[id+1];
  let prefix = contestId === defaultContest ? "" : `/contests/${contestId}`;

  return (
    <div>
        <nav style={{marginTop: -60}}>
            {prev &&
            <ul style={{textAlign:'left'}}>
                <li><Link to={`${prefix}/tasks/`+prev}><i className="fas fa-chevron-left"></i> {names[prev ] ?? ''}</Link></li>
            </ul>
            }
            <ul style={{textAlign:'center'}}>

                {contestId && contestId !== defaultContest ? 
                    <li><Link to={`/contests/${contestId}`}> Διαγωνισμός  <i className="fas fa-chevron-up"></i></Link></li>:
                    <li><Link to={`/tasks`}> Προβλήματα  <i className="fas fa-chevron-up"></i></Link></li>
                }
            </ul>
            {next&&
            <ul style={{textAlign:'right'}}>
                <li><Link to={`${prefix}/tasks/`+next}>{names[next ] ?? ''} <i className="fas fa-chevron-right"></i></Link></li>
            </ul>}
        </nav>
        <h1>
        {names[taskId] ?? ''} 
        </h1>
        <TaskView />
    </div>
  );
}

export default Task;
