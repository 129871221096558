import {useState} from 'react';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../firebase';
import './Header.css';


function Header({ user, menuOptions, logo, title }) {
const location = useLocation();
const { pathname } = location;
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    await auth.signOut();
    navigate('/');
  };

  return (<>
    <header>
        <nav className="container header-content">
            
            <ul className="logo"><NavLink to="/" className='contrast' >
                {logo && <img alt="logo" src={logo} /> }
                <li>
                    {title}
                </li>
            </NavLink></ul>
            <ul className='menu-options'>
                {menuOptions.map(({path, name, className},i) => <li key={i}>
                    <NavLink to={path} className={className}>{name}</NavLink>
                </li>)}
            </ul>

            <ul className="menu-buttons">
                <li>
                {user ? (
                        <Link to="#" onClick={handleLogout} className='contrast'>
                            <span>Αποσύνδεση</span>
                        </Link>
                ) : (
                    <NavLink to="/login" className='contrast'><span>Σύνδεση</span></NavLink>
                )}
                </li>
            </ul>
            <ul className="hamburger" onClick={() => setIsMenuOpen(true)}>
                <li><i className='fas fa-bars'/></li>
            </ul>
        </nav>
    </header>
    <aside className={`drawer-menu ${isMenuOpen ? 'open' : ''}`}>
        <nav className='container'>
        <ul  className="hamburger" onClick={() => setIsMenuOpen(false)}>
            <li><i className='fas fa-times'/></li>
        </ul>
        <ul>
            <li><h3 className="logo">{title}</h3></li>
        </ul>
        
        <ul>

            {menuOptions.map(({path,name},i) => <li  onClick={() => setIsMenuOpen(false)} key={i}>
            <NavLink to={path} className='contrast'>{name}</NavLink>    
            </li>)}
        </ul>
        <hr/>

        <ul className="buttons">
                <li  onClick={() => setIsMenuOpen(false)}>
                {user ? (
                        <Link to="#" onClick={handleLogout} className='contrast'>
                            <span>Αποσύνδεση</span>
                        </Link>
                ) : (
                    <NavLink to="/login" className='contrast'><span>Σύνδεση</span></NavLink>
                )}
                </li>
            </ul>
        </nav>
    </aside>
</>);
}

export default Header;
