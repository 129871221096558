import React from 'react';
import { loadFull } from "tsparticles";
import { Link } from 'react-router-dom';
import Particles from "react-tsparticles";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import styled from 'styled-components'

import archimedes from "../img/logos/archimedes.svg"
import eetn from "../img/logos/eetn.png"
import epy from "../img/logos/epy.jpg"

import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import robot from '../img/robot.lottie'
import graph from '../img/graph.lottie'
import prize from '../img/prize.lottie'

const Wrapper = styled.div`
h1.title {
  font-weight: 800;
}

@media (max-width:767px) {
section div {
  order: 0 !important;
}
}
.phases > div {
  border-radius: 10px;
  padding: 1em;
  color: #777;
  box-shadow: 0 5px 5px -5px black;
  transition: transform .2s;
  cursor: pointer;
}
.phases > div:nth-child(1) {
  transform: rotate(1deg);
}
.phases > div:nth-child(2) {
  transform: rotate(-1deg);
}
.phases > div:nth-child(1) {
  transform: rotate(1deg);
}
.phases > div:hover {
  transform: rotate(0) translateY(-10px);
}
.logos {
  display: flex;
  justify-content: center;
  gap: 1em;
}
.logos img {
  height: 2em;
}
`;

const HeroSection = () => {
  const particlesInit = async (main) => {
      await loadFull(main);
  };

  const particlesOptions = {
    fullScreen: { enable: false },
      particles: {
        number: {
          value: 50,
          density: {
            enable: true,
            area: 800
          }
        },
        color: {
          value: ["#2EB67D", "#ECB22E", "#E01E5B", "#36C5F0"]
        },
        shape: {
          type: "circle"
        },
        opacity: {
          value: 1
        },
        size: {
          value: { min: 1, max: 8 }
        },
        links: {
          enable: true,
          distance: 150,
          color: "#808080",
          opacity: 0.4,
          width: 1
        },
        move: {
          enable: true,
          speed: 2,
          direction: "none",
          random: false,
          straight: false,
          outModes: "out"
        }
      }
    };

  return (
      <section
          style={{
              minHeight: "50vh",
              position: "relative",
              overflow: "hidden",
              display: "flex", // Center content with Flexbox
              alignItems: "center", // Vertically center
              justifyContent: "center", // Horizontally center
          }}
      >
          <Particles
              id="tsparticles"
              init={particlesInit}
              options={particlesOptions}
              style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 1,
              }}
          />
          <div
              style={{
                  position: "relative",
                  zIndex: 2, // Ensure content is above particles
                  textAlign: "center",
              }}
          >
              <h1 className='title' style={{ fontSize: "3rem", margin: "0" }}>1ος Πανελλήνιος Μαθητικός Διαγωνισμός Τεχνητής Νοημοσύνης</h1>
              <p style={{ fontSize: "1.25rem", marginTop: "1rem" }}>Για μαθητές Γυμνασίου και Λυκείου.</p>
              <div className='logos' style={{ marginTop: "5rem" }}>
                <p>Με την συνδιοργάνωση:</p>
                <a href="https://archimedesai.gr"><img src={archimedes} height={50}/></a>
                <a href="https://epy.gr"><img src={epy}  height="70" /></a>
                <a href="https://eetn.gr"><img src={eetn}  height="50" /></a>
              </div>
          </div>
      </section>
  );
};

const Section = ({ image, text, reverse, lottie }) => {
  return (
      <section className='grid' style={{alignItems: 'center', margin: "50px 0"}}>
          <div style={{ aspectRatio:'3/2', backgroundImage: `url(${image})`,  backgroundSize: "contain", backgroundPosition: "center", order: reverse?1:0 }}>
            {lottie}
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div><ReactMarkdown rehypePlugins={[rehypeRaw]}>{text}</ReactMarkdown></div>
          </div>
          
      </section>
  );
};

function HomePage() {
  return (
    <Wrapper>
        <HeroSection />
        <Section
            lottie={
              <DotLottieReact
                src={robot}
                loop
                autoplay
              />}
            text={`
Είσαι έτοιμος να σπρώξεις τα όρια της τεχνολογίας και της δημιουργικότητας; Ο **1ος Πανελλήνιος Μαθητικός Διαγωνισμός Τεχνητής Νοημοσύνης (ΠΔΤΝ)** απευθύνεται σε μαθητές Γυμνασίου και Λυκείου που ονειρεύονται να φτιάξουν το μέλλον με τη δύναμη της Τεχνητής Νοημοσύνης.  

Δεν είναι απλώς ένας διαγωνισμός· είναι μια ευκαιρία να εξερευνήσεις τις δυνατότητές σου, να ανακαλύψεις νέους τρόπους σκέψης και να συμβάλεις στην επίλυση πραγματικών προβλημάτων.  
            `}
        />
        <Section
            lottie={
              <DotLottieReact
                src={graph}
                loop
                autoplay
              />}
            text={
              `### **Τι είναι ο ΠΔΤΝ;**  
Ο ΠΔΤΝ είναι μια ετήσια διοργάνωση που απευθύνεται σε μαθητές με πάθος για την τεχνολογία και τις επιστήμες των υπολογιστών. Ο διαγωνισμός προσφέρει την ευκαιρία στους μαθητές να:  
- **Μάθουν** σύγχρονες τεχνικές, όπως η μηχανική μάθηση, όραση υπολογιστών και επεξεργασία φυσικής γλώσσας.  
- **Δημιουργήσουν** λύσεις σε προκλήσεις εμπνευσμένες από καινοτόμες εφαρμογές της τεχνητής νοημοσύνης.  
- **Συνεργαστούν** με ειδικούς της ΤΝ και ταλαντούχους μαθητές από όλη την Ελλάδα. `
            }
            reverse
        />
        <Section
          lottie={
            <DotLottieReact
              src={prize}
              speed={0.7}
              loop
              autoplay
            />}
            text={`### **Γιατί να Συμμετέχεις;**  
- **Ανάδειξη Ταλέντων:** Ο διαγωνισμός βοηθά στην καλλιέργεια και προβολή ταλαντούχων μαθητών, 
με στόχο την επιλογή της ομάδας για την Διεθνή Ολυμπιάδα Τεχνητής Νοημοσύνης.  
- **Εκπαίδευση και Καθοδήγηση:** Συνεργασία με κορυφαίους ακαδημαϊκούς και επαγγελματίες του κλάδου.  
- **Προκλήσεις με Νόημα:** Ανάπτυξε λύσεις για προβλήματα με πραγματικό κοινωνικό αντίκτυπο.  
- **Προετοιμασία για το Μέλλον:** Εμπνεύσου να ακολουθήσεις καριέρες στις τεχνολογίες του αύριο.
`}
        />
        <div>
          <hr/>
          <br/>
          <h1>Οι φάσεις του διαγωνισμού</h1>
        <div className='phases grid'  style={{margin: "50px 0", textAlign: 'center'}}>
          <div style={{background: 'rgb(46 182 125 / 20%)'}}>
            <h1 style={{fontSize: '3rem', color: 'rgb(46 182 125)'}}>1.</h1>
            <h3>Προκριματική Φάση</h3>
            <p>Διαδικτυακή συμμετοχή με στόχο την επιλογή των κορυφαίων συμμετεχόντων.</p>
            <small>1 Ιαν 2025 - 5 Μαρ 2025</small>
          </div>
          <div style={{background: 'rgb(236, 178, 46, 20%)'}}>
            <h1 style={{fontSize: '3rem', color: 'rgb(236, 178, 46)'}}>2.</h1>
            <h3>Τελική Φάση</h3>
            <p>Με φυσική παρουσία σε εργαστήρια προηγμένης τεχνολογίας, όπου οι μαθητές αναπτύσσουν και παρουσιάζουν τις λύσεις τους.</p>
          </div>
          <div style={{background: 'rgb(54 197 240 / 20%)'}}>
            <h1 style={{fontSize: '3rem', color: 'rgb(54 197 240)'}}>3.</h1>
            <h3>Διεθνής Ολυμπιάδα</h3>
            <p>Οι καλύτεροι 4 διαγωνιζόμενοι θα επιλεγούν για τη συμμετοχή στη Διεθνή Ολυμπιάδα.</p>
          </div>
        </div>
        </div>
        <hr />
        <br/>
        <div className='grid'>
        <div><h3><strong>Έμπνευση, Δημιουργικότητα, Συνεργασία</strong></h3>
<p>Ο ΠΔΤΝ δεν είναι μόνο ένας διαγωνισμός· είναι μια πλατφόρμα που εμπνέει:</p>
<ul>
<li><strong>Νέες Ιδέες:</strong> Ενθαρρύνουμε τη δημιουργικότητα και την καινοτομία.</li>
<li><strong>Συνεργασία:</strong> Μαθητές από διαφορετικά υπόβαθρα ενώνονται για να δημιουργήσουν κάτι μοναδικό.</li>
<li><strong>Μάθηση:</strong> Εκπαιδευτικά προγράμματα και υλικό που δίνουν τη δυνατότητα σε όλους να εμβαθύνουν στις τεχνολογίες αιχμής.</li>
</ul>
</div>
<div>
<h3><strong>Πάρε Μέρος!</strong></h3>
<p>Με τη συμμετοχή σου στον <strong>1ο Πανελλήνιο Μαθητικό Διαγωνισμό Τεχνητής Νοημοσύνης</strong>, γίνε ένας από τους πρωτοπόρους που θα γράψουν το μέλλον της Τεχνητής Νοημοσύνης στην Ελλάδα και στον κόσμο. <strong>Κάνε Εγγραφή Τώρα</strong> και ζήσε την εμπειρία της γνώσης, της καινοτομίας και της δημιουργικότητας!</p>
<center><Link to={'/login'} role="button">Εγγραφή</Link></center>
</div>
</div>
    </Wrapper>
);
}

export default HomePage;
