import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

let md = `# Επιτροπές

Τα μέλη των επιτροπών του Πανελληνίου Διαγωνισμού Τεχνητής Νοημοσύνης (ΠΔΤΝ) για την σχολική χρονιά 2024-2025 είναι τα εξής:

<div class="grid">
<div>

## Οργανωτική Επιτροπή

#### Πρόεδρος
- Σελλής Τίμος, Διευθυντής Ερευν. Μονάδας ΑΡΧΙΜΗΔΗΣ

#### Αντιπρόεδρος
- Βογιατζής Ιωάννης, Καθ. Παν/μίου Δυτικής Αττικής, Πρόεδρος ΕΠΥ

#### Γραμματέας
- Καραγεώργος Παναγιώτης, Καθ. Πληροφορικής, Αντιπρόεδρος ΕΠΥ

#### Mέλη

- Καραταπάνης Κωστής, ΕΚ ΑΘΗΝΑ
- Καρυπίδης Στάθης, ΕΚ ΑΘΗΝΑ
- Κορομηλάς Παναγιώτης, ΕΚΠΑ
- Λώλος Ανδρέας, ΟΠΑ και ΕΚ ΑΘΗΝΑ
- Μελετίου Γεώργιος, ΕΠΥ
- Μοσχόβης Γιώργος, ΕΚ ΑΘΗΝΑ
- Πλα-Καρύδη Δανάη, ΕΚ ΑΘΗΝΑ
- Σουφλερή Ευσταθία, ΕΚ ΑΘΗΝΑ
- Τσικούρας Νίκος, ΕΚΠΑ και ΕΚ ΑΘΗΝΑ
- Χλαπάνης Οδυσσέας, ΟΠΑ και ΕΚ ΑΘΗΝΑ

</div>
<div>

## Επιστημονική Επιτροπή

#### Πρόεδρος
- Βούρος Γεώργιος, Καθηγητής Παν. Πειραιά, Πρόεδρος ΕΕΤΝ 

#### Αντιπρόεδρος
- Τζάμος Χρήστος, Αναπλ. Καθηγητής ΕΚΠΑ

#### Γραμματέας
- Μόρμορης Εμμανουήλ, Καθ. Πληροφορικής, ΕΠΥ

#### Mέλη

- Βουλόδημος Αθανάσιος, Επικ.Καθηγητής ΕΜΠ
- Καραμανής Κωνσταντίνος,Καθηγητής Univ. Texas Austin
- Κουμπαράκης Μανόλης, Καθηγητής ΕΚΠΑ
- Παλιούρας Γεώργιος, Διευθυντής Ερευνών ΕΚΕΦΕ ΔΗΜΟΚΡΙΤΟΣ
- Παναγάκης Ιωάννης, Καθηγητής ΕΚΠΑ
- Σγουροπούλου Κλειώ, Καθηγήτρια ΠΑΔΑ
- Σελλής Τίμος, Διευθυντής Ερευν. Μονάδας ΑΡΧΙΜΗΔΗΣ
- Στάμου Γεώργιος, Καθηγητής ΕΜΠ
- Σταφυλάκης Θέμος, Αναπλ.Καθηγητής ΟΠΑ
- Φωτάκης Δημήτρης, Καθηγητής ΕΜΠ
</div></div>`;


const MarkdownRenderer = ({ markdown }) => {
    return (
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{md}</ReactMarkdown>
    );
  };
  
  export default MarkdownRenderer;