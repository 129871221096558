import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import contests from "./contests-data.json"
import { ref,get } from 'firebase/database';
import { db } from '../firebase';

function Contests() {
  
  /*
  const [contests, setContests] = useState({
    '2025a': {'name':'Πρώτη φάση 2025'}
  });
  useEffect(() => {
    get(ref(db,'contests')).then((snapshot) => {
      const contestsData = snapshot.val();
      setContests(contestsData || {});
    });
  }, []);
  */

  if(!contests) return <span aria-busy="true">Γίνεται φόρτωση...</span>;
  return (
    <div>
      <h1>Διαγωνισμοί</h1>
      <ul>
        {Object.keys(contests).map((contest_id) => (
          <li key={contest_id}>
            <Link to={`/contests/${contest_id}`}>{contests[contest_id].name || contest_id}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Contests;
