import React, { useState } from 'react';
import {
    signOut, GoogleAuthProvider, signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail
} from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';



const google = new GoogleAuthProvider();

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");


    const navigate = useNavigate();

    function handleError(e) {
        console.log(e.message);
//        setError(e.message);
    }
    return (
        <div className="login">
            <div className="error">{error}</div>

            <h2>Σύνδεση με λογαριασμό Google</h2>
            <button 
                type="button" className="login__btn login__google" onClick={() => signInWithPopup(auth, google)
                            .then(() => navigate('/'))
                            .catch(handleError)}>
                Σύνδεση μέσω Google
            </button>
            <hr/>
            <h2>Σύνδεση με Email/Password</h2>
            <div className="grid">
                
                <input
                    type="text"
                    className="login__textBox"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail"
                />
                <input
                    type="password"
                    className="login__textBox"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Συνθηματικό"
                />
                <button
                    type="button"
                    onClick={() => signInWithEmailAndPassword(auth, email.trim(), password)
                                        .then(() => navigate('/'))
                                        .catch(handleError)}
                >
                    Σύνδεση
                </button>

                <button
                    type="button"
                    onClick={() => createUserWithEmailAndPassword(auth, email.trim(), password)        
                                .then(() => navigate('/'))
                                .catch(handleError)}>
                        Εγγραφή
                </button>
            </div>
            <div className="grid">
                <span></span>
                <button 
                    type="button"
                onClick={() => sendPasswordResetEmail(auth, email.trim()).catch(handleError)}>
                    Ξέχασα τον κωδικό
                </button>
            </div>
            
        </div>
    );
}

export default Login;
